<template>
  <div style="background-color: #ebf0ff; height: 100%">
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center py-5>
      <v-flex xs8 text-left>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px"
          >Unit List</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-16 v-if="assetList.length > 0">
      <v-flex xs10 sm8 v-for="(item, i) in assetList" pa-2 :key="i">
        <v-card outlined v-bind:style="{ border: '1px solid #7CB9E8' }">
          <v-layout wrap py-4 justify-start>
            <v-flex xs12 sm3 text-left>
              <v-layout wrap px-5>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratSemiBold;
                      font-size: 13px;
                      color: #000;
                    "
                    >Asset Name</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      font-size: 18px;
                      color: #000;
                    "
                    >{{ item.assetName }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratSemiBold;
                      font-size: 13px;
                      color: #000;
                    "
                    >Asset ID</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      font-size: 18px;
                      color: #000;
                    "
                    >{{ item.assetID }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratSemiBold;
                      font-size: 13px;
                      color: #000;
                    "
                    >Asset Type</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      font-size: 18px;
                      color: #000;
                    "
                    >{{ item.assetType }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm2 text-left>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratSemiBold;
                      font-size: 13px;
                      color: #000;
                    "
                    >Transaction Type</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span
                    style="
                      font-family: montserratRegular;
                      font-size: 18px;
                      color: #000;
                    "
                    >{{ item.transactionType }}</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 text-right px-5>
              <v-layout wrap>
                <v-flex xs12 pt-4>
                  <!-- :to="'/unitTransfer?id=' + item._id" -->
                  <v-btn
                    dark
                    small
                    rounded
                    color="#6AAFEE"
                    :to="
                      '/unitTransfer?id=' +
                      item._id +
                      '&assetID=' +
                      item.assetID
                    "
                  >
                    <span
                      style="
                        text-transform: none;
                        font-family: montserratRegular;
                      "
                      >Transfer Unit
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-16 v-else>
      <v-flex xs12>
        <span style="font-family: sedanRegular; font-size: 20px; color: #000"
          >No data found ...
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#6AAFEE"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    page: 1,
    currentPage: 1,
    pages: 0,
    limit: 10,
    appLoading: false,
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    assetList: [],
  }),
  watch: {
    currentPage() {
      this.getAsset();
    },
  },
  beforeMount() {
    this.getAsset();
  },
  methods: {
    getAsset() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/own/assets/latest/transactions/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          assetType: "Units",
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.assetList = response.data.data;
            this.pages = Math.ceil(response.data.totalLength / this.limit);
            this.appLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
